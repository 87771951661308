import React from 'react'
import PropTypes from 'prop-types'
import { Link, StaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <div id="container" className="group">
          <div id="bg_img">
            <h1 id="header"><Link to="/">Leelo Pascal</Link></h1>
            <Header />
            <div id="content" className="group">
              {children}
            </div>
            <div id="photo_leelo"></div>
            <a id="ms_link" className="social_link" href="https://www.facebook.com/Studio.CMI">Cible Music Inter CMI</a>
            <a id="fb_link" className="social_link" href="https://www.facebook.com/leelo.pascal">Leelo Pascal on Facebook</a>
            <div id="footer"></div>
          </div>
          <Link id="impressum" to="/remerciements/">Liens</Link>
          <a id="nl_logo" href="http://www.novalab.ch/" rel="nofollow">Novalab</a>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
