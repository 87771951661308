import { Link } from 'gatsby'
import React from 'react'

const activeNodeClass = 'activeNode';

const Header = () => (
  <div id="mainMenu">
    <ul>
      <li className="home-menu"><Link to="/" activeClassName={activeNodeClass}>Accueil</Link></li>
      <li><Link to="/biographie/" activeClassName={activeNodeClass}>Bio</Link></li>
      <li><Link to="/musique/" activeClassName={activeNodeClass}>Musique</Link></li>
      <li><Link to="/collaborations/" activeClassName={activeNodeClass}>Collaborations</Link></li>
      <li><Link to="/photos/" activeClassName={activeNodeClass}>Photos</Link></li>
      <li><Link to="/contact/" activeClassName={activeNodeClass}>Contact</Link></li>
    </ul>	
  </div>
)

export default Header
